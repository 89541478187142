import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import cloudFunction from "./cloudFunction";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.adminAccount(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty deployments dict
            if (!dbUser.deployments) {
              dbUser.deployments = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  doSendPasswordResetEmail = (emailAddress) => {
    return this.auth.sendPasswordResetEmail(emailAddress);
  };

  // Data access APIs
  adminAccount = (uid) => this.db.doc(`admin_account/${uid}`);
  deploymentAccounts = (deploymentId) =>
    this.db
      .collection(`deployment_account`)
      .where("deploymentId", "==", deploymentId);
  deploymentAccount = (deploymentAccountId) =>
    this.db.doc(`deployment_account/${deploymentAccountId}`);
  deployment = (pid) => this.db.doc(`deployments/${pid}`);
  deployments = () => this.db.collection("deployments");
  getCohortStats = (deploymentId, deploymentAccountId = null) =>
    // optionally, &reseedCohortStats=true which will re-calculate the this user's counters.
    cloudFunction(
      this,
      `getCohortStats?deploymentId=${deploymentId}&deploymentAccountId=${
        deploymentAccountId || ""
      }`
    );

  getAssessmentCategoryDistribution = (deploymentId, assessment) =>
    cloudFunction(
      this,
      `getAssessmentCategoryDistribution?deploymentId=${deploymentId}&assessment=${assessment}`
    );

  setAssessmentCategoryDistribution = (deploymentId, assessment, data) =>
    cloudFunction(
      this,
      `setAssessmentCategoryDistribution?deploymentId=${deploymentId}&assessment=${assessment}`,
      data
    );

  getKSEPCohortProgress = (deploymentId, startTime, endTime) =>
    cloudFunction(
      this,
      `getKSEPProgressByCohort?deploymentId=${deploymentId}&startTime=${startTime}&endTime=${endTime}`
    );

  getKSEPLearnerProgress = (
    deploymentId,
    deploymentAccountId,
    startTime,
    endTime
  ) =>
    cloudFunction(
      this,
      `getKSEPProgressByStudent?deploymentId=${deploymentId}&deploymentAccountId=${deploymentAccountId}&startTime=${startTime}&endTime=${endTime}`
    );

  getActivitiesCompletedOverTime = (deploymentId, deploymentAccountId = null) =>
    cloudFunction(
      this,
      `getActivitiesCompletedOverTime?deploymentId=${deploymentId}&deploymentAccountId=${
        deploymentAccountId || ""
      }`
    );

  getAssessmentScoresOverTime = (deploymentId, deploymentAccountId) =>
    cloudFunction(
      this,
      `getAssessmentScoresOverTime?deploymentId=${deploymentId}&deploymentAccountId=${
        deploymentAccountId || ""
      }`
    );

  getPerLessonGroupAssessmentScores = (deploymentId, deploymentAccountId) =>
    cloudFunction(
      this,
      `getPerLessonGroupAssessmentScores?deploymentId=${deploymentId}&deploymentAccountId=${
        deploymentAccountId || ""
      }`
    );

  getActivityDurationMetrics = (
    deploymentId,
    deploymentAccountId,
    activityType
  ) =>
    cloudFunction(
      this,
      `getActivityDurations?deploymentId=${deploymentId}&deploymentAccountId=${
        deploymentAccountId || ""
      }&activityName=${activityType || ""}`
    );
}

export default Firebase;
