import React, { useState } from "react";
import { withFirebase } from "../../util/Firebase";

const ResetPassword = ({ firebase }) => {
  let [email, setEmail] = useState("");
  let [isSent, setIsSent] = useState(false);

  const doReset = () => {
    setIsSent(true);
    firebase.doSendPasswordResetEmail(email);
  };

  if (isSent) {
    return (
      <h2 className="p-5">A reset link will arrive in your inbox shortly.</h2>
    );
  }

  return (
    <div className="w-50 p-5">
      <h1>Reset your password</h1>
      <p>Please input your e-mail below</p>
      <input
        name="email"
        className="form-control"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="Email Address"
        required
      />
      <p className="mt-2">
        <button onClick={doReset}>Reset</button>
      </p>
    </div>
  );
};

export default withFirebase(ResetPassword);
