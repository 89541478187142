import { CardProgress } from "./cardProgress";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withFirebase } from "../../../util/Firebase";
import { connect } from "react-redux";
import { mapKSEPCardProps } from "../../../util/CardProps";
import moment from "moment";

const SchoolReadinessProgress = ({ firebase, deploymentId }) => {
  const [ksepCardProps, setKsepCardProps] = useState(null);
  useEffect(() => {
    const getKSEPData = async () => {
      // Last month's data
      let ksepData = await firebase.getKSEPCohortProgress(
        deploymentId,
        moment().subtract(1, "months").valueOf(),
        moment().valueOf()
      );

      // Data from two months ago for comparison
      let ksepDataToCompare = await firebase.getKSEPCohortProgress(
        deploymentId,
        moment().subtract(2, "months").valueOf(),
        moment().subtract(1, "months").valueOf()
      );

      setKsepCardProps(mapKSEPCardProps(ksepData, ksepDataToCompare));
    };
    getKSEPData();
  }, []);

  return (
    <CardProgress
      title="School Readiness Progress"
      titleClass="assessment-title"
      subtitle="Aggregate 1-month rolling data for all learners"
      deploymentId={deploymentId}
      firebase={firebase}
      cardProps={ksepCardProps}
      displayConfig={true}
    />
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(SchoolReadinessProgress);
