import React, { useState, useEffect } from "react";
import { Alert, Modal, Row, Col, Button, Tooltip } from "reactstrap";
import { ClipLoader } from "react-spinners";
import "./ConfigureExam.scss";

/**
 * @component displays a modal form prefilled with the cohort's current assessment distribution
 * @param {Object}  props              contains component's props
 * @param {boolean} props.modal        states whether or not the modal is open
 * @param {Object}  props.firebase     is used to call firebase functions for fetching/updating assessment distribution
 * @param {string}  props.deploymentId is the ID specific to the cohort
 * @param {string}  props.assessment   is the assessment type the user wants to see/change the distribution of
 * @return {JSX.Element}               is a modal form that can be edited to change the assessment distribution
 */
export default function ConfigureExam(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [distribution, setDistribution] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const numUniqueLetters = 26;
  const numUniquePhonics = 18;
  const numUniqueColors = 10;
  const numUniqueRhymes = 15;
  const numUniqueShapes = 6;
  const numUniqueNumbers = 20;

  useEffect(() => {
    if (props.modal === true && !modalOpen) {
      toggleModal();
      if (data) {
        setDistribution(data);
        for (const category of Object.keys(data)) {
          setTooltipOpen((prev) => ({ ...prev, [category]: false }));
        }
      }
    }
  });

  const toggleTooltip = (category) => {
    setTooltipOpen((prev) => ({ ...prev, [category]: !tooltipOpen[category] }));
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const increment = (setVal, val) => {
    setDistribution((prev) => ({ ...prev, [setVal]: val + 1 }));
  };

  const decrement = (setVal, val) => {
    if (val > 0) {
      setDistribution({ ...distribution, [setVal]: val - 1 });
    }
  };

  const saveDistributon = (data) =>
    props.firebase.setAssessmentCategoryDistribution(
      props.deploymentId,
      props.assessment,
      data
    );

  const getData = () =>
    props.firebase.getAssessmentCategoryDistribution(
      props.deploymentId,
      props.assessment
    );

  useEffect(() => {
    const getDistribution = async () => {
      const res = await getData();
      setData(res);
    };

    getDistribution();
  }, [success]);

  // When the user submits the form, this function updates the relevant document in Cloud Firestore
  const handleUpdate = async () => {
    setPending(true);
    const res = await saveDistributon(distribution);
    if (res) {
      setPending(false);
      if (res.status.slice(0, 3) === "Err") {
        setError(true);
        setSuccess(false);
      } else {
        setError(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }
  };

  /**
   * @component for displaying an increment button and a decrement button
   * @param {string} setVal       is the distribution field that is being changed
   * @param {number} val          is the current value of the distribution field
   * @return {JSX.Element}        is an increment button placed directly above a decrement button
   */
  const changeValue = (setVal, val) => {
    return (
      <Col md={6}>
        <Row className="increment-row">
          <Button
            className="increment-btn"
            color="white"
            onClick={() => increment(setVal, val)}
          >
            <div className="plus">+</div>
          </Button>
        </Row>
        <Row className="decrement-row">
          <Button
            className="decrement-btn"
            color="white"
            onClick={() => decrement(setVal, val)}
          >
            <div className="minus">-</div>
          </Button>
        </Row>
      </Col>
    );
  };

  /**
   * @component for displaying the input field row for the given category
   * @param {string} categoryTitle      is the category title that is displayed
   * @param {number} numUniqueQuestions is the number of unique questions that exist for the given category
   * @param {string} dataField          is the distribution field corresponding to the category
   * @param {number} dataValue          is the current value of the distribution field
   * @return {JSX.Element}              is a row including the category title, data value, and the changeValue component
   */
  const field = (categoryTitle, numUniqueQuestions, dataField, dataValue) => {
    return (
      <Row className="field-row">
        <div className="info-wrapper" id={`tooltip-id-${categoryTitle}`}>
          <svg className="info-icon">
            <path d="M6.3 5.69a.942.942 0 01-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 01-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"></path>
          </svg>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[categoryTitle]}
            target={`tooltip-id-${categoryTitle}`}
            toggle={() => toggleTooltip(categoryTitle)}
            innerClassName="tooltip-sdg"
            arrowClassName="tooltip-sdg-arrow"
            autohide={false}
          >
            {numUniqueQuestions} unique questions
          </Tooltip>
        </div>
        <Col className="category-text">{categoryTitle}</Col>
        <Col>
          <Row className="input-row">
            <Col
              md={6}
              className="dist-val"
              // if question count is set to higher than the number of unique questions for that category, change its color to red to help make it clear to the teacher that there will be repeat questions
              style={{
                color: dataValue > numUniqueQuestions ? "#ff0f0f" : "black",
              }}
            >
              {dataValue}
            </Col>
            {changeValue(dataField, dataValue)}
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * @component for displaying the footer for the modal
   * @return {JSX.Element} is a row displaying the total question count, placed above a submit button for the form
   */
  const footer = () => {
    return (
      <div className="footer">
        <Row className="total-row">
          <Col md={{ span: 4, offset: 1 }} className="category-text">
            Total Questions
          </Col>
          <Col md={{ span: 2, offset: 5 }} className="total-val">
            {distribution.letters +
              distribution.numbers +
              distribution.colors +
              distribution.consonants +
              distribution.rhymes +
              distribution.shapes}
          </Col>
        </Row>
        <button onClick={handleUpdate} className="configure-exam-button">
          <div>Submit</div>
        </button>
      </div>
    );
  };

  return (
    <Modal
      isOpen={modalOpen}
      centered={true}
      toggle={toggleModal}
      className="distribution-modal"
      scrollable
      style={{ width: "100%", height: "100%" }}
    >
      {/* loading indicator if assessment distribution has not been fetched yet */}
      {!distribution && (
        <div
          className="text-center"
          style={{ paddingTop: "12rem", paddingBottom: "12rem" }}
        >
          <ClipLoader size={100} />
        </div>
      )}

      {/* currently, only the KSEP assessment's distribution can be changed as it has distinct categories */}
      {distribution && props.assessment === "ksepAssessment" && (
        <div>
          <div className="header-modal-text">School Readiness Assessment</div>

          {/* loading indicator for submission */}
          {pending && (
            <div className="text-center" style={{ paddingTop: "1.75rem" }}>
              <ClipLoader size={60} />
            </div>
          )}

          {/* submission error status */}
          {error && !pending && (
            <Alert color="danger" className="invalid-alert">
              <div className="error-message">
                Error updating category lengths.
              </div>
            </Alert>
          )}

          {/* submission success status */}
          {success && !pending && (
            <Alert color="success" className="valid-alert">
              <div className="valid-message">
                Successfully updated category lengths!
              </div>
            </Alert>
          )}

          {!success && !pending && !error && (
            <div className="disclaimer-text">
              If you choose to exceed the number of unique questions for a given
              category, your students will receive repeat questions for that
              category.{" "}
            </div>
          )}

          {/* form including each category in the assessment */}
          <div className="modal-body">
            {field(
              "Letters",
              numUniqueLetters,
              "letters",
              distribution.letters
            )}
            {field(
              "Phonics",
              numUniquePhonics,
              "consonants",
              distribution.consonants
            )}
            {field("Colors", numUniqueColors, "colors", distribution.colors)}
            {field("Rhyming", numUniqueRhymes, "rhymes", distribution.rhymes)}
            {field("Shapes", numUniqueShapes, "shapes", distribution.shapes)}
            {field(
              "Numbers",
              numUniqueNumbers,
              "numbers",
              distribution.numbers
            )}
          </div>
          {footer()}
        </div>
      )}
    </Modal>
  );
}
