import React, { Component } from "react";
import classnames from "classnames";

import styles from "./FlashcardImageGroup.module.scss";
export default class FlashcardImageGroup extends Component {
  render() {
    return (
      <>
        <div
          className={classnames(styles.cardGroup, {
            [styles.active]: this.props.isActive,
            [styles.inactive]: this.props.isInactive,
          })}
        >
          {this.props.isCompleted && (
            <i className={`${styles.completed} fa fa-check`}></i>
          )}
          {this.props.withSpiral && (
            <img className="spiral" src="assets/img/spiral.png" />
          )}
          <div className={`${styles.row} row`}>
            {this.props.flashcards.slice(0, 4).map((card) => {
              return (
                <div key={card.key} className={`col-6 ${styles.cardGroupCol}`}>
                  <img
                    src={
                      process.env.REACT_APP_GLEN_BASE_URL +
                      "/content/images/flashcards/" +
                      card.images[0]
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        {this.props.subText && (
          <div
            className={classnames(styles.cardTitle, {
              [styles.inactive]: this.props.isInactive,
            })}
          >
            <div className={`${styles.text}`}>{this.props.subText}</div>
          </div>
        )}
      </>
    );
  }
}
FlashcardImageGroup.defaultProps = {
  isActive: false,
  isInactive: false,
  isCompleted: false,
  withSpiral: false,
  subText: null,
  flashcards: [],
};
