import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export default ({ setStartTime, setEndTime }) => {
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(1, "months").valueOf())
  );
  const [endDate, setEndDate] = useState(new Date(moment().valueOf()));

  useEffect(() => {
    setStartTime(startDate);
    setEndTime(endDate);
  }, [startDate, endDate]);

  return (
    <div className="date-picker">
      <p className="date-range-title">Date range: </p>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <p className="date-divider">-</p>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );
};
