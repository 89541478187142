import React from "react";

import "./jumbotron.scss";
export default ({ title, description, image = "mbr-1920x1271.jpg" }) => {
  return (
    <div
      className="jumbotron with-image bg-cover"
      style={{ backgroundImage: `url('/banners/${image}')` }}
    >
      <div className="overlay"></div>
      <h1 className="display-4 font-weight-bold">{title}</h1>
      <p className="lead">{description}</p>
    </div>
  );
};
