import React from "react";
import { withFirebase, cloudFunction } from "../../util/Firebase";
import Jumbotron from "../../components/Jumbotron/jumbotron";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      isSubmitted: false,
    };
  }

  updateName(e) {
    this.setState({ name: e.target.value });
  }

  updateEmail(e) {
    this.setState({ email: e.target.value });
  }

  updatePhone(e) {
    this.setState({ phone: e.target.value });
  }

  updateMessage(e) {
    this.setState({ message: e.target.value });
  }

  sendMessage() {
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };

    cloudFunction(this.props.firebase, "contactSubmit", data).then(() => {
      this.setState({ isSubmitted: true });
    });
  }

  render() {
    if (this.state.isSubmitted) {
      return (
        <h4>
          Thank you for contacting us. We will review your message and get back
          soon.
        </h4>
      );
    }

    return (
      <>
        <Jumbotron
          title={"Feedback / Update Form"}
          description={
            "Please share any feedback regarding the dashboard or updates from your GLEN Learn deployment here. You can email us too."
          }
          image={"feedback_banner.jpg"}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <div className="controls">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="form_name">Name</label>
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder=""
                        required="required"
                        value={this.state.name}
                        onChange={this.updateName.bind(this)}
                        data-error="Firstname is required."
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="form_email">Email</label>
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder=""
                        required="required"
                        value={this.state.email}
                        onChange={this.updateEmail.bind(this)}
                        data-error="Valid email is required."
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="form_phone">Phone</label>
                      <input
                        id="form_phone"
                        type="tel"
                        name="phone"
                        className="form-control"
                        value={this.state.phone}
                        onChange={this.updatePhone.bind(this)}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="form_message">Message</label>
                      <textarea
                        id="form_message"
                        name="message"
                        className="form-control"
                        placeholder=""
                        rows="4"
                        required
                        value={this.state.message}
                        onChange={this.updateMessage.bind(this)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-success btn-send"
                      onClick={this.sendMessage.bind(this)}
                    >
                      Send message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withFirebase(Contact);
