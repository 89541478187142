import Timeline from "../../components/Timeline/Timeline";
import React from "react";

export default ({ account }) => {
  const events = account.profile.glenLearn.timelineEvents;
  return (
    <div className="text-center">
      <h1>{account.username}'s Timeline</h1>
      {!events && <div>No activity yet!</div>}
      {events && (
        <div style={{ overflowX: "scroll" }}>
          <Timeline timelineEvents={events} profile={account} />
        </div>
      )}
    </div>
  );
};
