const CohortTableColumns = [
  { title: "Name", field: "name" },
  { title: "Letters", field: "letters" },
  { title: "Phonics", field: "phonics" },
  { title: "Colors", field: "colors" },
  { title: "Rhyming", field: "rhymes" },
  { title: "Shapes", field: "shapes" },
  { title: "Numbers", field: "numbers" },
  { title: "Total Score", field: "totalScore" },
];

const LearnerTableColumns = [
  { title: "Date", field: "date" },
  { title: "Letters", field: "letters" },
  { title: "Phonics", field: "phonics" },
  { title: "Colors", field: "colors" },
  { title: "Rhyming", field: "rhymes" },
  { title: "Shapes", field: "shapes" },
  { title: "Numbers", field: "numbers" },
  { title: "Total Score", field: "totalScore" },
];

const Categories = [
  "colors",
  "shapes",
  "consonants",
  "letters",
  "rhymes",
  "numbers",
];

const TranslateCategories = {
  consonants: "phonics",
};

export {
  LearnerTableColumns,
  CohortTableColumns,
  Categories,
  TranslateCategories,
};
