import React, { useState, useEffect } from "react";
import { getGroupToCompletedConcepts } from "../../util/GWUtil/lessons";
import WordGroup from "../../components/WordGroup/WordGroup";

const WordGroups = ({ groups }) => {
  return (
    <div className="row">
      {Object.values(groups)
        .filter((cards) => cards.length > 0)
        .map((flashcards, index) => (
          <div key={index} className="col-4 mb-4">
            <WordGroup flashcards={flashcards} />
          </div>
        ))}
    </div>
  );
};

const WordsLearnedByUserPrintable = ({
  account,
  withSpanishTranslation = false,
}) => {
  const [groupToCompletedConcepts, setGroupToCompletedConcepts] = useState(
    null
  );
  useEffect(() => {
    const fetchData = async () => {
      setGroupToCompletedConcepts(
        await getGroupToCompletedConcepts(
          account.profile.glenLearn.lastMasteredLesson || 0
        )
      );
    };
    fetchData();
  }, []);

  const hasNoContent =
    groupToCompletedConcepts === null ||
    Object.values(groupToCompletedConcepts).every((g) => g.length === 0);
  return (
    <div className="text-center">
      <h1>Words Learned by {account.username} </h1>
      {withSpanishTranslation && (
        <h5>Palabras que aprendio {account.username}</h5>
      )}
      {hasNoContent && <>No words yet!</>}
      {!hasNoContent && <WordGroups groups={groupToCompletedConcepts} />}
    </div>
  );
};

export default WordsLearnedByUserPrintable;
