import React from "react";
import PropTypes from "prop-types";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/**
 * TabRenderer wraps the Tabs component from react-tabs, enabling conditional
 * rendering via a render prop. To use, pass in an array of objects with id and
 * name, and a render function that takes a tab id.
 */
const TabRenderer = ({ tabs, renderTab }) => {
  const tabList = tabs.map(({ name, id }) => <Tab key={id}>{name}</Tab>);
  const tabPanels = tabs.map(({ id }) => (
    <TabPanel key={id}>{renderTab(id)}</TabPanel>
  ));

  return (
    <Tabs>
      <TabList>{tabList}</TabList>
      {tabPanels}
    </Tabs>
  );
};

TabRenderer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  renderTab: PropTypes.func.isRequired,
};

export default TabRenderer;
