import React from "react";
import styles from "./WordGroup.module.scss";

export default ({ flashcards }) => {
  return (
    <div className={styles.wordGroup}>
      <div className="row">
        {flashcards.map((w) => (
          <div key={w.key} className="col-6">
            {w.meaning}
          </div>
        ))}
      </div>
    </div>
  );
};
