import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ClipLoader } from "react-spinners";
import { withFirebase } from "../../../util/Firebase";
import moment from "moment";
import round from "lodash/round";
import { useNonIdleRefresh } from "../../../util/hooks";

const ScatterGraph = ({ data }) => {
  //formatted like {"data":[{"date":"09/30/2019","values":[75,18.75],"mean":46.875}]}
  let scores = data
    .sort((a, b) => a.time - b.time)
    .map((d) => {
      const formatted = moment(d.time).format("MM/DD/YYYY");
      return {
        date: formatted,
        score: round(d.score / 20, 2) * 100,
      };
    });

  return (
    <ResponsiveContainer width="75%" height={350} className="ml-auto mr-auto">
      <ScatterChart
        data={scores}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="date" allowDuplicatedCategory={false} />
        <YAxis dataKey="score" unit="%" />
        <Brush dataKey="date" height={30} stroke="#8884d8" />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const ComprehensiveAssessmentScores = ({
  firebase,
  deploymentId,
  deploymentAccountId = null,
}) => {
  const getData = () =>
    firebase.getPerLessonGroupAssessmentScores(
      deploymentId,
      deploymentAccountId
    );
  const data = useNonIdleRefresh(getData);

  return (
    <div className="text-center">
      <h1>Per lesson group assessment scores</h1>
      <h5 className="text-muted mb-5">
        Scores for the assessment modules embedded in every lesson group
      </h5>
      <div>
        {data === null && <ClipLoader size={75} />}
        {data && data.length === 0 && <span>No data yet!</span>}
        {data && data.length > 0 && <ScatterGraph data={data} />}
      </div>
    </div>
  );
};
export default withFirebase(ComprehensiveAssessmentScores);
