class Resource {
  constructor() {
    this.data = null;
    this.location = null;
  }

  async populateIfNeeded() {
    // lazy load the complete data set upon first access
    if (this.data === null) {
      this.data = await this._getData();
    }
  }

  async all() {
    await this.populateIfNeeded();
    return this.data;
  }

  async get(key) {
    await this.populateIfNeeded();
    return { key, ...this.data[key] };
  }

  _getData = async () => {
    const response = await fetch(this.location, {
      mode: "cors",
    });
    return await response.json();
  };
}

class LessonResource extends Resource {
  constructor() {
    super();
    this.location =
      process.env.REACT_APP_GLEN_BASE_URL + "/content/json/lessons.json";
  }
}

class FlashcardResource extends Resource {
  constructor() {
    super();
    this.location =
      process.env.REACT_APP_GLEN_BASE_URL + "/content/json/flashcards.json";
  }
}

class CollectedWordGroupsResource extends Resource {
  constructor() {
    super();
    this.location =
      process.env.REACT_APP_GLEN_BASE_URL +
      "/content/json/collected_words_groups.json";
  }
}

const flashcardsService = new FlashcardResource();
const collectedWordGroupsService = new CollectedWordGroupsResource();
const lessonService = new LessonResource();
export { flashcardsService, collectedWordGroupsService, lessonService };
