import React, { useEffect, useState } from "react";
import PictureDictionary from "../../components/PictureDictionary/PictureDictionary";
import { getGroupToCompletedConcepts } from "../../util/GWUtil/lessons";

const WordsLearnedByUser = ({ account }) => {
  const [groupToCompletedConcepts, setGroupToCompletedConcepts] = useState(
    null
  );
  useEffect(() => {
    const lastMasteredLesson =
      account.profile.glenLearn.lastMasteredLesson || 0;
    const fetchData = async () => {
      setGroupToCompletedConcepts(
        await getGroupToCompletedConcepts(lastMasteredLesson)
      );
    };
    fetchData();
  }, []);

  // not loaded or no words in any of the groups
  const hasNoContent =
    groupToCompletedConcepts === null ||
    Object.values(groupToCompletedConcepts).every((g) => g.length === 0);
  return (
    <div className="text-center">
      <h1>Words Learned</h1>
      {hasNoContent && <>No words yet!</>}
      {!hasNoContent && (
        <PictureDictionary
          groupsToCompletedConcepts={groupToCompletedConcepts}
        />
      )}
    </div>
  );
};

export default WordsLearnedByUser;
