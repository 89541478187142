export const TYPES = {
  GLEN_LEARN: "glenLearn",
  GLEN_VOCAB: "glenVocab",
  GLEN_PHONICS: "glenPhonics",
  GLEN_SHAPES: "glenShapes",
  GLEN_ABC: "glenABC",
};

export const events = {
  GROUP_COMPLETED: "group_completed",
  WORD_MILESTONE: "word_milestone",
  CERTIFICATE: "certificate",
};

class TimelineEvent {
  constructor(type, meta, time) {
    this.type = type;
    this.meta = meta;
    this.time = time;
  }
  toJson() {
    return { type: this.type, meta: this.meta, time: this.time };
  }
}

export class GroupCompletedEvent extends TimelineEvent {
  constructor(lessonStart, lessonEnd, time = Date.now()) {
    super(events.GROUP_COMPLETED, { lessonStart, lessonEnd }, time);
  }
}

export class WordMilestoneEvent extends TimelineEvent {
  constructor(totalWords, time = Date.now()) {
    super(events.WORD_MILESTONE, { totalWords }, time);
  }
}
