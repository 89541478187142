import { ClipLoader } from "react-spinners";
import React from "react";
import { withFirebase } from "../../../util/Firebase";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Brush,
  XAxis,
  YAxis,
} from "recharts";
import { useNonIdleRefresh } from "../../../util/hooks";
import TabRenderer from "../../../components/TabRenderer";
import dataTabs from "./dataTabs";

const AreaGraph = ({ data }) => {
  return (
    <ResponsiveContainer height={350} className="ml-auto mr-auto">
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Brush dataKey="date" height={30} stroke="#8884d8" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="count"
          stackId="1"
          stroke="#ffc658"
          fill="#ffc658"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const ActivitiesCompletedOverTime = ({
  firebase,
  deploymentId,
  deploymentAccountId = null,
}) => {
  const getData = () =>
    firebase.getActivitiesCompletedOverTime(deploymentId, deploymentAccountId);
  const allData = useNonIdleRefresh(getData);

  const renderTab = (tabId) => {
    const data = allData && allData[tabId]; // data is null if allData is null

    return (
      <div>
        {data === null && <ClipLoader size={75} />}
        {data && data.length === 0 && <span>No data yet!</span>}
        {data && data.length > 0 && <AreaGraph data={data} />}
      </div>
    );
  };

  return (
    <div className="text-center">
      <h1>Activities completed</h1>
      <h5 className="text-muted mb-5">
        Total number of activities completed over time
      </h5>
      <TabRenderer tabs={dataTabs} renderTab={renderTab} />
    </div>
  );
};
export default withFirebase(ActivitiesCompletedOverTime);
