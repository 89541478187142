export const PERMISSION = Object.freeze({
  ADMIN: "admin", //exists in user.deployments
  NONRANDOMIZED: "nonrandomized", //exists in user.readOnlyDeployments
  RANDOMIZED: "randomized", //exists in user.readOnlyDeployments
});

export const getHashedUsername = (username) => {
  var hash = 0,
    i,
    chr;
  if (username.length === 0) return hash;
  for (i = 0; i < username.length; i++) {
    chr = username.charCodeAt(i);
    hash += chr;
  }
  return "User " + hash;
};
