import React, { useEffect, useState, useMemo } from "react";
import MaterialTable from "@material-table/core";
import "react-datepicker/dist/react-datepicker.css";

import DateRange from "./DateRange";

import { parseStudentKSEPData } from "../../../util/Table/parseData";
import { LearnerTableColumns } from "../../../constants/table";
import "./table.scss";

/**
 * @component for displaying a table with student metrics
 * @param {Object} props                     contains component's props
 * @param {string} props.title               is the table section's title
 * @param {string} props.subtitle            is the table section's subtitle
 * @param {string} props.deploymentId        is the cohort ID used for querying relevant data
 * @param {string} props.deploymentAccountId describes the student ID used for querying relevant data
 * @param {Object} props.firebase            contains all the firebase functions
 * @return {JSX.Element}                     is a div containing the table and titles
 */
const Table = ({
  title,
  subtitle,
  deploymentId,
  deploymentAccountId,
  firebase,
}) => {
  const [ksepData, setKSEPData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    const getKSEPData = async () => {
      // We convert the Date objects to unix time
      const startUnixTime = startTime.getTime();
      const endUnixTime = endTime.getTime();

      setIsLoading(true);
      const resp = await firebase.getKSEPLearnerProgress(
        deploymentId,
        deploymentAccountId,
        startUnixTime,
        endUnixTime
      );
      setIsLoading(false);

      setKSEPData(resp);
    };

    if (startTime && endTime) {
      getKSEPData();
    }
  }, [startTime, endTime]);

  const tableData = useMemo(() => {
    if (!ksepData) {
      return;
    }

    // Parses the ksep data into an object with column keys for the table
    return parseStudentKSEPData(ksepData.learnerStats);
  }, [ksepData]);

  return (
    <div className="progress-table-container">
      <h2>{title}</h2>
      {subtitle && <h5 className="text-muted">{subtitle}</h5>}
      <div className="progress-table">
        <MaterialTable
          columns={LearnerTableColumns}
          data={tableData}
          title={
            <DateRange setStartTime={setStartTime} setEndTime={setEndTime} />
          }
          isLoading={loading}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.parentId)
          }
        />
      </div>
    </div>
  );
};

export { Table };
