import React, { useEffect, useState, useMemo } from "react";
import MaterialTable from "@material-table/core";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import DateRange from "./DateRange";

import { parseCohortKSEPData } from "../../../util/Table/parseData";
import { CohortTableColumns } from "../../../constants/table";
import "./table.scss";

/**
 * @component for displaying a table with cohort metrics
 * @param {Object} props              contains component's props
 * @param {string} props.title        is the table section's title
 * @param {string} props.subtitle     is the table section's subtitle
 * @param {string} props.deploymentId describes the cohort ID used for querying relevant data
 * @param {Object} props.firebase     contains all the firebase functions
 * @param {Object} props.usernames    maps deploymentAccountId to its corresponding username
 * @return {JSX.Element}              is a div containing the table and titles
 */
const Table = ({ title, subtitle, deploymentId, firebase, usernames }) => {
  const [ksepData, setKSEPData] = useState(null);
  // Initial startTime is 1 month earlier than the current day
  const [startTime, setStartTime] = useState(
    new Date(moment().subtract(1, "months").valueOf())
  );
  // Initial endTime is the current day
  const [endTime, setEndTime] = useState(new Date(moment().valueOf()));
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    const getKSEPData = async () => {
      // We convert the Date objects to unix time
      const startUnixTime = startTime.getTime();
      const endUnixTime = endTime.getTime();

      setIsLoading(true);
      const resp = await firebase.getKSEPCohortProgress(
        deploymentId,
        startUnixTime,
        endUnixTime
      );
      setIsLoading(false);

      setKSEPData(resp);
    };

    if (startTime && endTime) {
      getKSEPData();
    }
  }, [startTime, endTime]);

  const tableData = useMemo(() => {
    if (!usernames || !ksepData) {
      return;
    }

    /**
     * Parses the ksep data into an object with column keys for the table
     * and average scores per category
     */
    return parseCohortKSEPData(ksepData.learnerStats, usernames);
  }, [ksepData]);

  return (
    <div className="progress-table-container">
      <h2>{title}</h2>
      {subtitle && <h5 className="text-muted">{subtitle}</h5>}
      <div className="progress-table">
        <MaterialTable
          columns={CohortTableColumns}
          data={tableData ? tableData.cohortKSEPData : undefined}
          title={
            <DateRange setStartTime={setStartTime} setEndTime={setEndTime} />
          }
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export { Table };
