import React from "react";
import styles from "./flashcard.module.scss";

export default ({ card }) => {
  return (
    <div className={styles.card}>
      <div>
        <img
          className={styles.image}
          src={
            process.env.REACT_APP_GLEN_BASE_URL +
            "/content/images/flashcards/" +
            card.images[0]
          }
        />
      </div>
      <div className={styles.text}>{card.meaning}</div>
    </div>
  );
};
