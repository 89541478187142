//keep in sync with glen learn app: src/util/Analytics/events.js

export const activity = {
  LESSON: "lesson",
  VOCAB: "vocab",
  COMPREHENSIVE_ASSESSMENT: "comprehensiveAssessment",
  VOCAB_ASSESS: "vocabAssess",
  VOCAB_STRENGTHEN: "vocabStrengthen",
  PHONIC_ASSESS: "phonicAssess",
  PHONIC_STRENGTHEN: "phonicStrengthen",
  ENTERTAINMENT: "entertainment",
  BOOK: "book",
  RHYME: "rhyme",
  NUMBERS_BOOK: "numbersBook",
  SOUND_CLICK: "soundClick",
  SOUND_CLICK_1: "soundClick1",
  SOUND_CLICK_2: "soundClick2",
  IMAGE_CLICK: "imageClick",
  WORD_CLICK: "wordClick",
  GLEN_MATCH: "glenMatch",
  GLEN_WRITE: "glenWrite",
  DESCRIBE_IT: "describeIt",
  CONSONANT_SOUNDS: "consonantSounds",
  TRACE_AND_SOUND: "traceAndSound",
  SELECT_ANSWER: "selectAnswer",
  MULTIPLE_CHOICE: "multipleChoice",
  ALPHABET_SYNC: "alphabetSync",
};
