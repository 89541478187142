import React, { useMemo } from "react";
import { withFirebase } from "../../../util/Firebase";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import styles from "./individualLearnerProgress.module.scss";

// see https://github.com/mbrn/material-table/issues/2252
import MaterialTable from "@material-table/core";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const IndividualLearnerProgress = ({ deploymentId, usernames }) => {
  const columns = useMemo(
    () => [
      {
        title: "Name",
        customSort: (a, b) => a.username.localeCompare(b.username),
        render: (account) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="w-25">
                <img
                  className={styles.avatar}
                  src={`${process.env.REACT_APP_GLEN_BASE_URL}/images/avatars/avatar${account.avatarId}.png`}
                />
              </div>
              <div className="w-75 font-weight-bold">{account.username}</div>
            </div>
          );
        },
      },
      {
        title: "Activities Completed",
        field: "counters.activitiesCompleted",
        render: (rowData) =>
          rowData.counters?.activitiesCompleted?.toLocaleString(),
      },
      {
        title: "Words Learned",
        field: "counters.wordsLearned",
      },
      {
        title: "Stories Narrated",
        field: "counters.storiesNarrated",
      },
      {
        title: "Rhymes Read Aloud",
        field: "counters.rhymesReadAloud",
      },
      {
        title: "Last Active",
        render: (account) => {
          return account.lastActive
            ? ` ${timeAgo.format(new Date(account.lastActive), "round-minute")}`
            : " Unknown";
        },
        customSort: (a, b) => a.lastActive - b.lastActive,
      },
      {
        title: "",
        render: (account) => {
          return (
            <Link
              to={`/deployment/${deploymentId}/user/${account.id}`}
              className="btn btn-sm btn-outline-secondary"
            >
              View profile
            </Link>
          );
        },
      },
    ],
    [deploymentId]
  );

  const accounts = useMemo(
    () =>
      Object.values(usernames).sort((a, b) =>
        a.username.localeCompare(b.username)
      ),
    [usernames]
  );
  return (
    <div>
      <h2>Individual Learner Progress</h2>
      <h5 className="text-muted mb-5">
        Click on a user below for individual learner analytics
      </h5>
      {accounts === null && <ClipLoader size={75} />}
      {accounts !== null && (
        <MaterialTable title="" columns={columns} data={accounts} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(IndividualLearnerProgress);
