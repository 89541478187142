import round from "lodash/round";
import { Categories, TranslateCategories } from "../../constants/table";

const parseCohortKSEPData = (ksepData, usernames) => {
  const totalScores = [];

  const tableData = Object.entries(ksepData).map(([accountId, values]) => {
    const rowObj = { name: usernames[accountId].username };

    let total = 0;

    Categories.forEach((category) => {
      const key =
        category in TranslateCategories
          ? TranslateCategories[category]
          : category;

      if (category in values) {
        rowObj[key] = round(values[category], 1);
        total += values[category];
      } else {
        rowObj[key] = "-";
      }
    });

    const totalScore = total / Object.keys(values).length;
    rowObj.totalScore = round(totalScore, 1);

    totalScores.push(totalScore);

    return rowObj;
  });

  const parsedKSEPTableData = {
    cohortKSEPData: tableData,
    categoryAvgs: calculateCategoryAverages(tableData, totalScores),
  };

  return parsedKSEPTableData;
};

const parseStudentKSEPData = (ksepData) => {
  const tableData = [];

  for (const [date, values] of Object.entries(ksepData)) {
    const rowObjDate = { date, id: date };

    // We set a null id for the children because we only care about their parents and set column placeholders
    const rowObjNum = { parentId: date, id: null, date: "Total Questions" };

    let totalScore = 0;
    let totalAnswered = 0;

    Categories.forEach((category) => {
      const key =
        category in TranslateCategories
          ? TranslateCategories[category]
          : category;

      if (category in values) {
        rowObjDate[key] = round(values[category].score, 1);
        rowObjNum[key] = values[category].total;
        totalScore += values[category].score;
        totalAnswered += values[category].total;
      } else {
        rowObjDate[key] = "-";
        rowObjNum[key] = "-";
      }
    });

    totalScore = totalScore / Object.keys(values).length;
    rowObjDate.totalScore = round(totalScore, 1);

    rowObjNum.totalScore = totalAnswered;

    tableData.push(rowObjDate);
    tableData.push(rowObjNum);
  }

  return tableData;
};

const calculateCategoryAverages = (rows, totalScores, checkDate) => {
  const categoryAvgs = {};
  Categories.forEach((category) => {
    let categorySum = 0;
    let count = 0;

    for (const row of rows) {
      if (checkDate && !row.date) {
        continue;
      }

      if (category in TranslateCategories) {
        category = TranslateCategories[category];
      }

      if (row[category] && row[category] !== "-") {
        categorySum += row[category];
        count += 1;
      }
    }

    categoryAvgs[category] = round(categorySum / count, 1);
  });

  if (totalScores.length > 0) {
    categoryAvgs.totalScore = round(
      totalScores.reduce((a, b) => a + b) / totalScores.length,
      1
    );
  }

  return categoryAvgs;
};

export { parseCohortKSEPData, parseStudentKSEPData };
