import { CardDescriptions, CardImages } from "../../constants/cards";

const CARD_SECTIONS = [
  "activitiesCompleted",
  "wordsLearned",
  "storiesNarrated",
  "rhymesReadAloud",
];

/**
 * Maps comprehensive data to card props for displaying at the top of the dashboard
 * @param {Object} counters contains comprehensive metrics
 * @return {Array}          list of card props
 */
export function mapComprehensiveCardProps(counters) {
  return CARD_SECTIONS.map((key) => {
    return {
      imgPath: `/progress/${CardImages[key]}.png`,
      value: counters?.[key] ?? 0,
      description: CardDescriptions[key],
    };
  });
}
