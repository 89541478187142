import React, { useState, useEffect, useMemo } from "react";
import times from "lodash/times";
import moment from "moment";

import { withFirebase } from "../../util/Firebase";
import { CardProgress } from "../Deployment/sections/cardProgress";
import {
  mapComprehensiveCardProps,
  mapKSEPCardProps,
} from "../../util/CardProps";
import { Table } from "../Deployment/sections/studentTable";

const Star = () => (
  <svg viewBox="0 0 50 50" height="60" width="60">
    <path
      fill="yellow"
      stroke="#000"
      d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
    />
  </svg>
);
let CohortProgressForUser = ({
  firebase,
  account,
  deploymentId,
  deploymentAccountId,
  withSpanishTranslation = false,
  withSchoolReadiness = true,
  withStudentScores = true,
}) => {
  const [ksepData, setKSEPData] = useState(null);
  const [ksepDataToCompare, setKSEPDataToCompare] = useState(null);

  const lastLessonMastered = account.profile.glenLearn.lastMasteredLesson
    ? Math.floor(account.profile.glenLearn.lastMasteredLesson / 5)
    : 0;
  useEffect(() => {
    const getKSEPData = async () => {
      // Last month's data
      let resp = await firebase.getKSEPLearnerProgress(
        deploymentId,
        deploymentAccountId,
        moment().subtract(1, "months").valueOf(),
        moment().valueOf()
      );
      setKSEPData(resp);

      // Data from two months ago for comparison
      resp = await firebase.getKSEPLearnerProgress(
        deploymentId,
        deploymentAccountId,
        moment().subtract(2, "months").valueOf(),
        moment().subtract(1, "months").valueOf()
      );
      setKSEPDataToCompare(resp);
    };

    getKSEPData();
  }, []);

  const filterScores = (data) => {
    for (const [date, categories] of Object.entries(data)) {
      for (const category of Object.keys(categories)) {
        data[date][category] = data[date][category].score;
      }
    }

    return data;
  };

  // Memoization used since mapping to KSEP cards is an expensive operation
  const ksepCardProps = useMemo(() => {
    if (ksepData && ksepDataToCompare) {
      ksepData.learnerStats = filterScores(ksepData.learnerStats);
      ksepDataToCompare.learnerStats = filterScores(
        ksepDataToCompare.learnerStats
      );

      return mapKSEPCardProps(ksepData, ksepDataToCompare);
    }
  }, [ksepData, ksepDataToCompare]);

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <h1>{account.username}'s Achievements</h1>
        {withSpanishTranslation && <h5>Los logros de {account.username}</h5>}
        <div className="mt-3">
          {times(lastLessonMastered).map((x, i) => (
            <span key={i} className="p-2">
              <Star />
            </span>
          ))}
        </div>
      </div>
      <div className="container">
        <CardProgress
          title="Student Progress"
          subtitle={`Aggregate data for ${account.username}`}
          cardProps={mapComprehensiveCardProps(account.counters)}
        />
        {withSchoolReadiness && (
          <CardProgress
            title="School Readiness Progress"
            titleClass="assessment-title"
            subtitle={`Aggregate 1-month rolling data for ${account.username}`}
            cardProps={ksepCardProps}
          />
        )}
        {withStudentScores && (
          <Table
            title="Student Scores"
            subtitle="Total assessments"
            deploymentId={deploymentId}
            deploymentAccountId={deploymentAccountId}
            firebase={firebase}
          />
        )}
      </div>
    </div>
  );
};
export default withFirebase(CohortProgressForUser);
