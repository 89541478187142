import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import React from "react";

const LandingNonAuth = () => (
  <div className="container text-center">
    <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <img
        className="mb-3"
        src="/GWlogo_spiral_300x195.png"
        alt=""
        width="100"
        height="66"
      />
      <h1 className="display-4">GLEN World Dashboard</h1>
      <p className="lead">
        To get started, please register an account. Once you have registered,
        the GLEN World team will be notified.
      </p>
      <Link className="btn btn-outline-primary" to={ROUTES.SIGN_IN}>
        Sign In
      </Link>
      &nbsp;
      <Link className="btn btn-outline-primary" to={ROUTES.SIGN_UP}>
        Register
      </Link>
    </div>
  </div>
);
export default LandingNonAuth;
