import React from "react";
import { ClipLoader } from "react-spinners";
import { mapComprehensiveCardProps } from "../../util/CardProps";
import { CardProgress } from "./sections/cardProgress";
import { Table } from "./sections/cohortTable";
import { withFirebase } from "../../util/Firebase";
import ActivitiesCompletedOverTime from "./sections/activitiesCompletedOverTime";
import ComprehensiveAssessmentScores from "./sections/comprehensiveAssessmentScores";
import PerLessonGroupAssessmentScores from "./sections/perLessonGroupAssessmentScores";
import IndividualLearnerProgress from "./sections/individualLearnerProgress";
import Jumbotron from "./../../components/Jumbotron/jumbotron.js";
import { useNonIdleRefresh } from "../../util/hooks";
import { getHashedUsername, PERMISSION } from "../../util/permissions";
import { compose } from "recompose";
import { connect } from "react-redux";
import SchoolReadinessProgress from "./sections/schoolReadinessProgress";

const useDeploymentAccounts = (firebase, deploymentId, authUser) => {
  const permissionLevel =
    authUser.deployments[deploymentId] ||
    authUser.readOnlyDeployments[deploymentId];
  const getData = async () => {
    let usernameMap = {};
    await firebase
      .deploymentAccounts(deploymentId)
      .get()
      .then((snapshot) =>
        snapshot.docs.map((d) => {
          const data = d.data();
          //TODO: Move hashing to backend
          data.username =
            permissionLevel === PERMISSION.RANDOMIZED
              ? getHashedUsername(data.username)
              : data.username;
          usernameMap[d.id] = { id: d.id, ...data };
          return true;
        })
      );
    return usernameMap;
  };
  return useNonIdleRefresh(getData);
};

const Deployment = ({ match, firebase, authUser }) => {
  const deploymentId = match.params.deploymentId;
  const usernames = useDeploymentAccounts(firebase, deploymentId, authUser);

  const deployment = useNonIdleRefresh(() =>
    firebase
      .deployment(deploymentId)
      .get()
      .then((d) => d.data())
  );

  if (deployment === null) {
    return (
      <div className="text-center">
        <ClipLoader size={100} />
      </div>
    );
  }

  return (
    <>
      <Jumbotron
        title={`Dashboard: ${deployment.name}`}
        description={deployment.description}
        image={"mbr-1920x1271.jpg"}
      />
      <div className="container">
        <CardProgress
          title="Cohort Progress"
          subtitle="Aggregate data for all learners"
          cardProps={mapComprehensiveCardProps(deployment.counters)}
        />
        <IndividualLearnerProgress
          deploymentId={deploymentId}
          usernames={usernames}
        />
        <SchoolReadinessProgress deploymentId={deploymentId} />
        <Table
          title="Class Scores"
          subtitle="Average by assessments"
          deploymentId={deploymentId}
          firebase={firebase}
          usernames={usernames}
        />
        <hr className="mt-5 mb-5" />
        <ActivitiesCompletedOverTime deploymentId={deploymentId} />
        <hr className="mt-5 mb-5" />
        <ComprehensiveAssessmentScores deploymentId={deploymentId} />
        <hr className="mt-5 mb-5" />
        <PerLessonGroupAssessmentScores deploymentId={deploymentId} />
        <hr className="mt-5 mb-5" />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(Deployment);
