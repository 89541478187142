import round from "lodash/round";

import { CategoryValues } from "../../constants/cards";

/**
 * Calculates the average of an input array
 * @param {Array} arr       array of values to average
 * @param {number} decimals number of decimals in response
 * @return {numebr}         average rounded to the number of decimals
 */
const calculateAverage = (arr, decimals) => {
  if (arr.length === 0) {
    return 0;
  }

  let total = 0;

  for (const value of arr) {
    total += value;
  }

  const average = total / arr.length;

  return decimals ? round(average, decimals) : average;
};

/**
 * Calculates the average of averages mapped to every key in the input object
 * @param {Object} assessments contains keys mapped to corresponding scores
 * @param {number} decimals    number of decimals in response
 * @return {number}            average rounded to the number of decimals
 */
const calculateGroupAverage = (assessments, decimals) => {
  const compiledAverages = [];

  for (const stats of Object.values(assessments)) {
    const average = calculateAverage(Object.values(stats));
    compiledAverages.push(average);
  }

  const cohortAverage = calculateAverage(compiledAverages, decimals);

  return cohortAverage;
};

/**
 * Compiles the averages accross the same category in different keys of the assessments
 * @param {Object} assessments contains keys mapped to corresponding scores
 * @return {Object}            contains categories mapped to their averages
 */
const collectCategoryStats = (assessments) => {
  const categoryCohortStats = {};

  for (const stats of Object.values(assessments)) {
    for (const [category, value] of Object.entries(stats)) {
      if (!(category in categoryCohortStats)) {
        categoryCohortStats[category] = [value];
      } else {
        categoryCohortStats[category].push(value);
      }
    }
  }

  return categoryCohortStats;
};

/**
 * Calculates the best and worst category based on their respective averages
 * @param {Object} assessments contains keys mapped to corresponding scores
 * @return {Object}            contains best and worst category names
 */
const calculateCategoryMetrics = (assessments) => {
  const categoryStats = collectCategoryStats(assessments);

  // default value for best and worst category is "-" representing no best or worst categories
  let bestCategory = "-";
  let bestValue = 0;
  let worstCategory = "-";
  let worstValue = Infinity;

  for (const [category, stats] of Object.entries(categoryStats)) {
    const categoryAverage = calculateAverage(stats, 1);

    if (categoryAverage >= bestValue) {
      bestCategory = category;
      bestValue = categoryAverage;
    }

    if (categoryAverage <= worstValue) {
      worstCategory = category;
      worstValue = categoryAverage;
    }
  }

  return { bestCategory, worstCategory };
};

/**
 * Maps ksepData to card props for displaying at the top of the dashboard
 * @param {Object} ksepData          contains assessment data
 * @param {Object} ksepDataToCompare contains assessment data from previous period
 * @return {Array}                   list of card props
 */
export function mapKSEPCardProps(ksepData, ksepDataToCompare) {
  const cardProps = [];
  const currStudentStats = ksepData.learnerStats;
  const oldStudentStats = ksepDataToCompare.learnerStats;

  const cohortAverage = calculateGroupAverage(currStudentStats, 1);
  const assessmentsTaken = ksepData.numAssessmentsTaken;
  const { bestCategory, worstCategory } = calculateCategoryMetrics(
    currStudentStats
  );

  // Calculates the changes between the old and new data
  const averageChange =
    cohortAverage - calculateGroupAverage(oldStudentStats, 1);
  const assessmentsTakenChange =
    assessmentsTaken - ksepDataToCompare.numAssessmentsTaken;

  // Creates card props with precalculated values
  cardProps.push({
    imgPath: `/progress/assessment.png`,
    value: assessmentsTaken,
    description: "Completed Assessments",
    change: assessmentsTakenChange,
  });

  cardProps.push({
    imgPath: `/progress/avg.png`,
    value: `${cohortAverage}%`,
    description: "Average Score",
    change: averageChange,
  });

  const bestPath =
    bestCategory === "-"
      ? "/progress/dashboard.png"
      : `/progress/${bestCategory}.png`;
  const worstPath =
    worstCategory === "-"
      ? "/progress/dashboard.png"
      : `/progress/${worstCategory}.png`;

  cardProps.push({
    imgPath: bestPath,
    value: CategoryValues[bestCategory],
    description: "Best Category",
  });

  cardProps.push({
    imgPath: worstPath,
    value: CategoryValues[worstCategory],
    description: "Lowest Category",
  });

  return cardProps;
}
