import React, { useEffect, useRef, useState } from "react";
import { withFirebase } from "../../util/Firebase";
import Jumbotron from "../../components/Jumbotron/jumbotron";
import { ClipLoader } from "react-spinners";
import ActivitiesCompletedOverTime from "../Deployment/sections/activitiesCompletedOverTime";
import ComprehensiveAssessmentScores from "../Deployment/sections/comprehensiveAssessmentScores";
import PerLessonGroupAssessmentScores from "../Deployment/sections/perLessonGroupAssessmentScores";
import CohortProgressForUser from "./cohortProgressForUser";
import ReactToPrint from "react-to-print";
import WordsLearnedByUser from "./wordsLearnedByUser";
import WordsLearnedByUserPrintable from "./wordsLearnedByUserPrintable";
import { getHashedUsername, PERMISSION } from "../../util/permissions";
import { compose } from "recompose";
import { connect } from "react-redux";
import TimeSpent from "./timeSpent";
import Timeline from "./timeline";

class PrintableCohortProgressForUser extends React.Component {
  // this needs to be a component for ReactToPrint, which requires a ref (functional components in react don't
  // allow refs)
  render() {
    return (
      <div>
        <img
          alt="Logo"
          src="/GWlogo_spiral_300x195.png"
          style={{ width: "4.5vw", height: "3.0vw" }}
        />
        <h1 className="text-center">GLEN Learn</h1>
        <CohortProgressForUser
          {...this.props}
          withSchoolReadiness={false}
          withStudentScores={false}
          withSpanishTranslation={true}
        />
        <WordsLearnedByUserPrintable
          {...this.props}
          withSpanishTranslation={true}
        />
      </div>
    );
  }
}

const DeploymentUser = (props) => {
  const { deploymentId, deploymentAccountId } = props.match.params;
  const printRef = useRef();

  const [account, setAccount] = useState(null);
  useEffect(() => {
    props.firebase
      .deploymentAccount(deploymentAccountId)
      .get()
      .then((d) => {
        const permissionLevel =
          props.authUser.deployments[deploymentId] ||
          props.authUser.readOnlyDeployments[deploymentId];

        const accountData = d.data();
        setAccount({
          ...accountData,
          username:
            permissionLevel === PERMISSION.RANDOMIZED
              ? getHashedUsername(accountData.username)
              : accountData.username,
        });
      });
  }, [deploymentAccountId, deploymentId]);

  if (account === null) {
    return (
      <div className="text-center">
        <ClipLoader size={100} />
      </div>
    );
  }

  return (
    <>
      <div className="text-right float-right align-top">
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-link" onClick={() => window.print()}>
              Print
            </button>
          )}
          content={() => printRef.current}
          pageStyle={`body { padding: 5%; }`}
        />
      </div>
      <Jumbotron
        title={`Learner Progress: ${account.username}`}
        image={"mbr-1920x1271.jpg"}
      />
      <div className="container">
        <CohortProgressForUser
          account={account}
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
        />
        <hr className="mt-5 mb-5" />
        <Timeline account={account} />
        <hr className="mt-5 mb-5" />
        <WordsLearnedByUser account={account} />
        <hr className="mt-5 mb-5" />
        <ActivitiesCompletedOverTime
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
        />
        <hr className="mt-5 mb-5" />
        <ComprehensiveAssessmentScores
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
        />
        <hr className="mt-5 mb-5" />
        <PerLessonGroupAssessmentScores
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
        />
        <hr className="mt-5 mb-5" />
        <TimeSpent
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
        />
      </div>
      <div style={{ display: "none" }}>
        <PrintableCohortProgressForUser
          account={account}
          deploymentId={deploymentId}
          deploymentAccountId={deploymentAccountId}
          ref={printRef}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(DeploymentUser);
