import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  Brush,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { ClipLoader } from "react-spinners";
import { withFirebase } from "../../../util/Firebase";
import { useNonIdleRefresh } from "../../../util/hooks";
import TabRenderer from "../../../components/TabRenderer";
import dataTabs from "./dataTabs";

const ScatterGraph = ({ data }) => {
  //formatted like {"data":[{"date":"09/30/2019","values":[75,18.75],"mean":46.875}]}
  let scores = [];
  let means = [];
  data
    .sort((a, b) => a.date - b.date)
    .forEach((d) => {
      means.push({ x: d.date, y: d.mean });
      d.values.forEach((v) => {
        scores.push({ x: d.date, y: v });
      });
    });

  const formatter = (value, name, props) => {
    return [value, name];
  };

  // see https://github.com/recharts/recharts/issues/956
  return (
    <ResponsiveContainer height={350} className="ml-auto mr-auto">
      <ScatterChart
        data={scores}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="x" name="date" allowDuplicatedCategory={false} />
        <YAxis dataKey="y" name="score" unit="%" />
        <Brush dataKey="x" height={30} stroke="#8884d8" />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} formatter={formatter} />
        <Scatter fill="#8884d8" />
        <Scatter data={means} fill="#000000">
          <LabelList dataKey="y" position="bottom" />
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const ComprehensiveAssessmentScores = ({
  firebase,
  deploymentId,
  deploymentAccountId = null,
}) => {
  const getData = () =>
    firebase.getAssessmentScoresOverTime(deploymentId, deploymentAccountId);
  const allData = useNonIdleRefresh(getData);

  const renderTab = (tabId) => {
    const data = allData && allData[tabId]; // data is null if allData is null

    return (
      <div>
        {data === null && <ClipLoader size={75} />}
        {data && data.length === 0 && <span>No data yet!</span>}
        {data && data.length > 0 && <ScatterGraph data={data} />}
      </div>
    );
  };

  return (
    <div className="text-center">
      <h1>Assessment scores</h1>
      <h5 className="text-muted mb-5">Assessment scores over time</h5>
      <TabRenderer tabs={dataTabs} renderTab={renderTab} />
    </div>
  );
};
export default withFirebase(ComprehensiveAssessmentScores);
