import React, { useState, useEffect } from "react";
import { withFirebase } from "../../util/Firebase";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import { activity } from "../../util/GWUtil/constants";

function useTimeSpent(
  deploymentId,
  deploymentAccountId,
  activityType,
  firebase
) {
  const [timeSpentData, setTimeSpentData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setTimeSpentData(
        await firebase.getActivityDurationMetrics(
          deploymentId,
          deploymentAccountId,
          activityType
        )
      );
    };
    fetchData();
  }, []);
  return timeSpentData;
}

const GREEN = "#00ff00";
const RED = "#ff0000";
const BLUE = "#0000ff";

const TimeSpentForExercise = withFirebase(
  ({
    correctColor = GREEN,
    incorrectColor = RED,
    deploymentId,
    deploymentAccountId,
    activityName,
    firebase,
  }) => {
    const timeSpentData = useTimeSpent(
      deploymentId,
      deploymentAccountId,
      activityName,
      firebase
    );
    if (!timeSpentData) {
      return <ClipLoader />;
    }
    //filter out unreasonably long lengths
    const data = timeSpentData
      .filter((t) => t.duration < 2500)
      .map((t, i) => {
        //so we don't start at "Game 0"
        return {
          x: i + 1,
          y: t.duration,
          wasCorrect: t.meta.wasCorrect,
          date: t.completeTime,
        };
      });
    if (data.length === 0) {
      return <div className="mb-4 text-muted">No data yet</div>;
    }
    const wasCorrect = data.filter(
      (d) => d.wasCorrect || typeof d.wasCorrect === "undefined"
    );
    const wasIncorrect = data.filter((d) => d.wasCorrect === false);
    const formatter = (value, name, props) => {
      if (name === "date") {
        return [moment.unix(value).format("MM/DD/YYYY"), name];
      }
      return [value, name];
    };

    return (
      <ResponsiveContainer
        width="100%"
        height={350}
        className="ml-auto mr-auto"
      >
        <ScatterChart>
          <CartesianGrid />
          <XAxis
            domain={[(dataMin) => 0, (dataMax) => dataMax + 1]}
            dataKey="x"
            name="Game number"
            allowDecimals={false}
            type="number"
            tickFormatter={(time) => `#${time}`}
          />
          <YAxis
            dataKey="y"
            name="Seconds"
            type="number"
            label={{
              value: "Time taken (s)",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} formatter={formatter} />
          <Scatter data={wasCorrect} fill={correctColor} shape="circle" />
          <Scatter data={wasIncorrect} fill={incorrectColor} shape="circle" />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
);

const activityTypes = {
  [activity.IMAGE_CLICK]: { name: "Image Click" },
  [activity.WORD_CLICK]: { name: "Word Click" },
  [activity.SOUND_CLICK]: { name: "Sound Click" },
  [activity.GLEN_MATCH]: { name: "GLEN Match", correctColor: BLUE },
  [activity.GLEN_WRITE]: { name: "Write Word", correctColor: BLUE },
  [activity.BOOK]: { name: "Stories", correctColor: BLUE },
  [activity.RHYME]: { name: "Rhymes", correctColor: BLUE },
  [activity.DESCRIBE_IT]: { name: "Describe It" },
  [activity.TRACE_AND_SOUND]: { name: "Trace and Sound" },
  [activity.SOUND_CLICK_2]: { name: "Choose Sound" },
  [activity.CONSONANT_SOUNDS]: { name: "Sound Start" },
  [activity.SELECT_ANSWER]: { name: "Select Answer" },
};
const TimeSpent = ({ deploymentId, deploymentAccountId }) => {
  return (
    <div className="text-center">
      <h1>Time spent by exercise</h1>
      <h5 className="text-muted mb-5">
        Duration in seconds spent on each exercise type (green indicates correct
        answer and red indicates incorrect answer)
      </h5>
      <div className="row">
        {Object.keys(activityTypes).map((a) => {
          return (
            <div className="col-6" key={a}>
              <h3>{activityTypes[a].name}</h3>
              <TimeSpentForExercise
                activityName={a}
                deploymentId={deploymentId}
                deploymentAccountId={deploymentAccountId}
                correctColor={activityTypes[a].correctColor}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeSpent;
