import React, { useState } from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import ConfigureExam from "../../../components/ConfigureExam/ConfigureExam";
import "./cardProgress.scss";

/**
 * @component for displaying an indicator representing change in a card's value
 * @param {Object} props        contains component's props
 * @param {number} props.change value of change
 * @return {JSX.Element}        is a colored arrow and value
 */
const Indicator = ({ change }) => {
  return (
    <div className="indicator">
      {/* uses appropriate arrow image depending on change value */}
      {change > 0 ? (
        <img
          alt="positive"
          src="/progress/positive.svg"
          style={{ width: "14px" }}
        />
      ) : (
        <img
          alt="negative"
          src="/progress/negative.svg"
          style={{ width: "14px" }}
        />
      )}

      {/* uses color for text depending on change value */}
      <div className={change > 0 ? "change-text-green" : "change-text-red"}>
        <p>{Math.abs(Math.round(change))}</p>
      </div>
    </div>
  );
};

Indicator.propTypes = {
  change: PropTypes.number.isRequired,
};

/**
 * @component for displaying a metric and associated image
 * @param {Object} props                contains component's props
 * @param {string} props.imgPath        is a path to the icon image
 * @param {number|String} props.value   is the value of the metric
 * @param {String} props.description    describes the metric
 * @param {String} props.subDescription optional, additional description
 * @param {number} props.change         optional, change value
 * @return {JSX.Element}                is a rectangle containing the image, value, and description(s)
 */
const Card = ({
  imgPath,
  value,
  description,
  subDescription,
  change,
  size = "lg",
}) => {
  return (
    <div className="col mb-2">
      <div className="counter">
        <img
          alt="card"
          src={imgPath}
          style={{ width: size === "lg" ? "90px" : "65px" }}
        />
        <div className="counter-container">
          <h2 className="timer count-title count-number">
            {value === undefined || value === 0 ? "-" : value.toLocaleString()}
          </h2>
          {change !== undefined && change !== "-" && change !== 0 && (
            <Indicator change={change} />
          )}
        </div>
        <p className="count-text">{description}</p>
        <p className="count-text-sub">{subDescription}</p>
      </div>
    </div>
  );
};

Card.propTypes = {
  imgPath: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string,
  change: PropTypes.number,
};

/**
 * @component creates a series of Card components for displaying key progress metrics
 * @param {Object} props                contains component's props
 * @param {string=} props.title         is an optional title for the cards (ex: "School Readiness Progress")
 * @param {string=} props.titleClass    is an optional class applied to the title
 * @param {string=} props.subtitle      is an optional, additional description
 * @param {Object} props.cardProps      props to be passed to each Card component
 * @param {Object=} props.deploymentId  identifies the cohort
 * @param {Object=} props.firebase      supports interaction with firebase functions
 * @param {Object=} props.displayConfig dictates if the config button should be displayed
 * @return {JSX.Element}                is a row of Card components
 */
const CardProgress = ({
  title,
  titleClass,
  subtitle,
  cardProps,
  deploymentId,
  firebase,
  displayConfig,
  size = "lg",
}) => {
  const [modal, setModal] = useState(false);
  const waitTime = 200;

  const handleForm = () => {
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, waitTime);
  };

  return (
    <div className="row mb-4">
      <div className="col-12 col-lg-6">
        {titleClass ? (
          <h2 className={titleClass}>{title}</h2>
        ) : (
          title && <h2>{title}</h2>
        )}
        {subtitle && <h5 className="text-muted">{subtitle}</h5>}
      </div>
      {title === "School Readiness Progress" && displayConfig && (
        <div className="col-12 col-lg-6">
          <button onClick={() => handleForm()} className="configure-exam-btn">
            Configure Exam
          </button>
          <ConfigureExam
            modal={modal}
            firebase={firebase}
            assessment="ksepAssessment"
            deploymentId={deploymentId}
          />
        </div>
      )}
      {/* loading indicator */}
      {!cardProps && <ClipLoader size={75} />}

      {cardProps && (
        <div className="container">
          <div className="row justify-content-md-center">
            {/* each prop is mapped to a new Card component */}
            {cardProps.map((props, i) => (
              <Card key={i} {...props} size={size} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CardProgress.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
  subtitle: PropTypes.string,
  cardProps: PropTypes.array,
  deploymentId: PropTypes.string,
  firebase: PropTypes.object,
  displayConfig: PropTypes.bool,
};

export { CardProgress };
