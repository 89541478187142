import { useState } from "react";

// Currently, the only type of timeline modal supported is certificates.
const useTimelineModal = (timelineEvents) => {
  const [selectedCertIndex, setSelectedCertIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(!modalOpen);

  const openModal = (uuid) => {
    let i = timelineEvents.findIndex((event) => event.id === uuid);
    setSelectedCertIndex(i);
    setModalOpen(true);
  };

  return {
    selectedCertIndex,
    modalOpen,
    openModal,
    closeModal,
  };
};

export default useTimelineModal;
